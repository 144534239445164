@import '~antd/dist/antd.css';
@page { 
  size: auto;
  margin: 8mm 16mm 8mm 16mm;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.long-link {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.square-container {
  background-color: white;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative
}

.login-background {
  background-image:    url(/images/login_background.jpg);
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;              /* optional, center the image */
}

.color-green {
  color: green;
}

.gray-background {
  background-color: #f9f9f9;
}

.freeze-scroll {
  overflow: hidden;
}
.freeze-scroll-x {
  overflow-x: hidden;
}
.self-scroll {
  overflow-y: auto;
}
.self-scroll-x {
  overflow-x: auto;
}

.line-through {
  text-decoration: line-through;
}

.max-two-lines {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.selectable:hover { 
  cursor: pointer;
  background-color: #fff5e6;
}
.selected {
  background-color: #fff5e6;
}

.hoverable {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.single-line {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
}

.html-editor {
  height: 560px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-screen-height {
  height: 100vh;
}

.full-page {
  width: 100%;
  height: 100%;
}

.flex {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-fill {
  -webkit-flex: 1; /* Safari */
  flex: 1;
}
.flex-row {
  flex-direction: row;
  -webkit-flex-direction: row; /* Safari */
}
.flex-column {
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-center {
  -webkit-justify-content: center; /* Safari */
  justify-content: center;
}
.flex-wrap {
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
}
.flex-space-between {
  -webkit-justify-content: space-between; /* Safari */
  justify-content: space-between;
}
.flex-space-around {
  -webkit-justify-content: space-around; /* Safari */
  justify-content: space-around;
}
.flex-align-center {
  align-items: center;
  -webkit-align-items: center; /* Safari */
}
.flex-align-start {
  align-items: flex-start;
  -webkit-align-items: flex-start; /* Safari */
}
.flex-align-end {
  align-items: flex-end;
  -webkit-align-items: flex-end; /* Safari */
}
.flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end; /* Safari */
}
.margin {
  margin: 16px;
}
.margin-half {
  margin: 8px;
}
.margin-top {
  margin-top: 16px;
}
.margin-top-half {
margin-top: 8px;
}
.margin-bottom {
  margin-bottom: 16px;
}
.margin-bottom-half {
margin-bottom: 8px;
}
.margin-horizontal {
  margin: 0px 16px;
}
.margin-horizontal-half {
margin: 0px 8px;
}
.margin-vertical {
  margin: 16px 0px;
}
.margin-right {
  margin-right: 16px;
}
.margin-left {
  margin-left: 16px;
}
.padding-half {
 padding: 8px;
}
.padding {
  padding: 16px;
}
.padding-top {
padding-top: 16px;
}
.padding-top-half {
  padding-top: 8px;
}
.padding-bottom {
  padding-bottom: 16px;
}
.padding-bottom-half {
padding-bottom: 8px;
}
.padding-left {
  padding-left: 16px;
}
.padding-left-half {
padding-left: 8px;
}
.padding-right {
  padding-right: 16px;
}
.padding-right-half {
padding-right: 8px;
}
.padding-horizontal {
  padding: 0px 16px;
}
.padding-horizontal-half {
  padding: 0px 8px;
}
.padding-vertical {
  padding: 16px 0px;
}
.padding-vertical-half {
padding: 8px 0px;
}
.font-white {
  color: white;
}
.font-primary {
  color: #f56a00;
}
.font-error {
  color: red;
  font-size: 12px;
  height: 20px;
}
.chip {
  background-color: #EEEEEE;
  border-radius: 20px;
  padding: 4px 8px 8px 8px;
  margin: 2px;
  white-space: nowrap;
}